<template>
  <div class="means">
    <PageTopNav :pageNavShow="false" :addShow="false" :tabList="tabList">
    </PageTopNav>
    <div class="outDiv">
      <div class="mains">
        <div class="lists" v-if="option.series[0].data.length">
          <v-chart class="v-chart" autoresize :option="option"></v-chart>
        </div>
        <div class="lists" v-else>
          <MyNothing></MyNothing>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import { getProcessStatisticsApi } from "@/request/api";
import { mapState } from "vuex";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);
export default {
  provide: {
    [THEME_KEY]: "dark",
  },
  components: {
    VChart,
    PageTopNav: () => import("@/components/PageTopNav.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      option: {
        backgroundColor: "transparent",
        title: {
          text: "产品生产统计",
          left: "center",
        },
        // tooltip: {
        //   formatter: function (params) {
        //     return '';
        //   },
        // },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      loading: false,
      loadingSelect: false,
      planList: [],
      planTotal: 0,
      planPn: 1,
      // 分页
      total: 0, // 总数
      last_page: 1, //末尾页
      per_page: 10, // 一页个数
      current_page: 1, // 当前页
      // 库存记录的分页
      supplyTotal: 0, // 总数
      supplyPer_page: 10, // 一页个数
      supplyCurrent_page: 1, // 当前页
      // 筛选条件
      title: undefined,
      cid: undefined,
      // 生产记录分类列表
      tabList: [],
      // 生产记录列表
      meansList: [],
      isFocusShow: true,
      // 入库/出库
      dialogVisible: false,
      // 生产记录入库/出库
      quantity: "",
      purposeVal: "",
      PopTitle: "",
      inputLab: "",
      resource_id: "",
      key_word: "",

      //   添加
      addShow: false,
      bigPopTitle: "",
      // 添加生产记录表单
      form: {
        name: "",
        num: "",
        unit: "",
        model: "",
        product_id: "",
        dry_id: "",
      },
      // 生产记录ID
      id: "",
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        model: [{ required: true, message: "请输入规格", trigger: "blur" }],
        num: [{ required: true, message: "请输入生产数量", trigger: "blur" }],
        dry_id: [
          { required: true, message: "请选择烘干记录", trigger: "change" },
        ],
      },

      formLabelWidth: "120px",
    };
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getProcess();
  },
  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },

    // 获取生产记录详情
    // async getAddResourceApi() {
    //   const { code, results } = await getAddResourceApi({
    //     resource_id: this.id,
    //   });
    //   if (code === 0) {
    //     results.yank = results.yank + "";
    //     this.form = results;
    //     this.form.supplier = results.cid;

    //     this.form.custom_state
    //       ? (this.form.custom_state = true)
    //       : (this.form.custom_state = false);
    //   }
    // },

    // 切换分页
    handleCurrentChange(val) {
      this.current_page = val;
      this.getProcess();
    },
    // 获取生产记录列表
    async getProcess() {
      showFullScreenLoading();
      this.option.series[0].data = [];
      const { code, results } = await getProcessStatisticsApi();
      if (code === 0) {
        this.$forceUpdate();
        results.data.forEach((t) => {
          let obj = {};
          obj.name = t.product_name;
          obj.value = t.total_num;
          obj.unit = t.unit;
          obj.model = t.model;
          this.option.series[0].data.push(obj);
        });
      }
      tryHideFullScreenLoading();
    },

    changeTab(cid) {
      this.cid = cid.id;
      this.current_page = 1;
      this.title = undefined;
      this.getProcess();
    },

    // 编辑生产记录
    editMean(id) {
      this.id = id;
      // this.getAddResourceApi();
      this.bigPopTitle = "编辑生产记录";
      this.addShow = true;
    },
    // 添加生产记录
    addMeans() {
      this.bigPopTitle = "添加生产记录";
      this.addShow = true;
    },

    setAddClose() {
      this.addShow = false;
      this.form = {};
    },
  },
};
</script>

<style lang="less" scoped>
.means {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  .outDiv {
    padding: 0 48px;
    .mains {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      background-color: #0e2138;
      padding: 22px 49px;

      .lists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .items {
          margin: 0 40px 34px 0;
          width: 307px;
          height: 328px;
          background: #081c30;
          border: 1px solid #385576;
          padding: 13px 15px 0;
          display: flex;
          flex-direction: column;

          .col1 {
            width: 100%;
            height: 184px;
            margin-bottom: 14px;
            position: relative;

            .bgImgs {
              width: 100%;
              height: 100%;
            }

            .tags {
              min-width: 108px;
              text-align: center;
              height: 30px;
              line-height: 30px;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              padding: 0 8px;
              position: absolute;
              left: 0;
              top: 12px;
              color: #fff;
              font-weight: Bold;
              font-size: 16px;
              background-color: #6200bd;
            }

            .icon-btn {
              display: flex;
              align-items: center;
              display: none;
              position: absolute;
              right: 13px;
              top: 12px;

              img {
                width: 33px;
                height: 32px;
                cursor: pointer;
                margin-right: 9px;

                :last-of-type {
                  margin-right: 0;
                }
              }
            }
          }

          .col2 {
            width: 100%;
            border-radius: 14px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            > div {
              width: 5px;
              height: 5px;
              background: #a46dd7;
              border-radius: 50%;
              margin-right: 11px;
            }

            p {
              width: calc(100% - 5px);
              height: 20px;
              color: #fff;
              font-size: 14px;
              margin: 0;
            }
          }

          .col3 {
            width: 100%;
            min-height: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a9b8c8;
            line-height: 20px;
            margin-bottom: 10px;
          }

          .col4 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .buttons1,
            .buttons2,
            .buttons3 {
              width: 82px;
              height: 28px;
              border-radius: 3px;
              color: #fff;
              font-size: 14px;
              line-height: 28px;
              text-align: center;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }

            .buttons1 {
              background: #67c23a;
            }

            .buttons2 {
              background: #3e90e5;
            }

            .buttons3 {
              background: #67c23a;
            }
          }

          &:nth-of-type(5n) {
            margin-right: 0;
          }

          :hover {
            .icon-btn {
              display: block;
            }
          }
        }
        .v-chart {
          width: 100%;
          height: 60vh;
        }
      }
    }
  }

  .big-pop {
    /deep/.el-dialog {
      width: 666px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 662px;
    height: 415px;
    background: url(../../assets/image/land/popBgS.png) no-repeat center center;
    background-size: cover;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;
      align-items: center;
      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }

    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      display: flex;
      padding: 0 62px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  .big-pop {
    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 54px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 666px;
      height: 693px;
      background: url(../../assets/image/land/popBgB.png) no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px;
      overflow: hidden;
      .addForm {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          color: #fff;
          margin-right: 16px;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }

  /deep/.uploadCover {
    width: 158px;
    height: 105px;
    display: flex;
    .el-upload--picture-card {
      width: 100%;
      height: 100%;
      line-height: 105px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 158px;
      height: 105px;
    }
    &.active {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
}
</style>
